import React, {useState, useEffect} from 'react'
import Layout from '../../components/layout'
import HubspotForm from "react-hubspot-form"
import AireCarousel from '../../components/Testimonials/AireCarousel'
import Helmet from "react-helmet"

const Aire = ({ location }) => {
  return (
   <Layout location = {location}>

        <Helmet>
            <title>AIRᵉ Consultation | Vantage Circle</title>

            <meta name="description" content="Design & optimize your Rewards & Recognition program to be more impactful using our pioneering AIRe Framework." />
            <meta property="twitter:title" content="AIRᵉ Consultation | Vantage Circle" />
            <meta name= "robots" content= "index" />
                    
            <meta property="twitter:description" content="Design & optimize your Rewards & Recognition program to be more impactful using our pioneering AIRe Framework." />
                    
            <meta property="og:type" content="website"/>
                    
            <meta property="og:url" content="https://www.vantagecircle.com/services/aire-consultation//"/>

            <meta property="og:site_name" content="Vantage Circle"/>
            <meta property="og:title" content="AIRᵉ Consultation | Vantage Circle" />

            <meta property="og:description" content="Design & optimize your Rewards & Recognition program to be more impactful using our pioneering AIRe Framework." />
            <link rel="canonical" href="https://www.vantagecircle.com/services/aire-consultation/"></link>
        </Helmet>


         <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-3 xl:pb-20 overflow-y-hidden z-0 px-3 lg:px-0">
            <div class="max-w-7xl xl:flex justify-center items-start z-10 lg:px-3 relative">
                <div class="w-full md:w-10/12 xl:w-11/12 mx-auto flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-3 xl:pt-12 xl:pb-2">
                        <div class="my-3 flex justify-center items-center px-8 py-2 rounded-full bg-indigo-50">
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2022/02/AIRe_Logo_-1.png" alt="AIRe_Logo_-1" height="90" width="110" />
                        </div>
                        <h1 class="text-purple-100 lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-5xl font-bold text-center xl:text-left">Maximize your ROI on <span class="text-orange">Rewards & Recognition</span> <br/> with AIR<sup>e</sup> Framework</h1>
                        <p class="mt-8 mb-4 text-lg xl:text-xl text-purple-100 text-center xl:text-left w-full">Is your investment in Rewards & Recognition not generating the desired returns? Design & optimize an authentic & impactful R&R program using our pioneering AIR<sup>e</sup> Framework</p>
                        <div className="xl:grid grid-cols-2 gap-5 justify-center items-start lg:justify-start w-5/6 lg:w-full xl:w-full cursor-pointer mt-7 mb-2">
                            <a className="vc-new-orange-btn-rounded-full lato rounded-full mt-7 mb-3 xl:mb-0 xl:mt-0" href="#form">Book a free consultation</a>
                            <div className=''>
                                <a className="vc-new-white-border-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="/tools/aire-score-assessment/">Assess your R&R program</a>
                                {/* <p className='text-green py-2'>Its Free!</p>  */}
                            </div>
                            
                        </div>  
                        {/* <p className='text-green hidden xl:flex'>Its Free!</p>  */}
                        <a href = "/aire-benchmarking-report/" className='text-orange mt-5 mb-3 z-20' style={{ textDecoration: 'underline' }}>Download Global AIR<sup>e</sup> Benchmarking Report 2022-23</a>
                        <a href = "/aire-benchmarking-report-india/" className='text-orange mb-3 z-20' style={{ textDecoration: 'underline' }}>Download AIR<sup>e</sup> Benchmarking Report SHRM India 2023-24</a>   
   
                </div>
                <div class="xl:w-9/12 h-10 xl:h-80"></div>
                <div className='h-96 w-96 rounded-full bg-indigo-50 absolute -bottom-48 xl:-bottom-40 -right-2 md:right-40 lg:right-80 xl:right-20 transform md:scale-150 z-0'></div>
                <div class="absolute -bottom-80 md:scale-90 transform xl:scale-90 md:right-28 lg:right-1/4 xl:-bottom-10 xl:right-24 hidden xl:flex justify-center items-center h-full mt-6 pt-5 xl:mt-0 lg:ml-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/AIRe-Flow-Vertical.png" alt="Vantage Rewards-AIRe-Flow-Vertical" width="350" /></div>
                <div className='flex xl:hidden mx-auto'><img className='mx-auto z-10' decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/AIRe-Flow-Vertical.png" alt="Vantage Rewards-AIRe-Flow-Vertical" width="300" /></div>
            </div>
        </section>
        <section className='w-full py-10 xl:py-16 bg-purple-100 px-6'>
            <div className='mx-auto max-w-7xl'>
                <p className='text-center text-gray-500 text-xl xl:text-2xl max-w-4xl mx-auto pb-3'>Companies are investing $46Bn on R&R programs but a majority are not achieving the desired results. Why?</p>
                <h2 className='text-center text-gray-500 text-2xl xl:text-4xl max-w-5xl mx-auto'><span className="text-red">68%</span> of companies perceive the effectiveness of their R&R programs as moderate to very low.</h2>
                <div className='grid grid-cols-1 xl:grid-cols-3 gap-10 my-10 xl:mt-20 w-11/12 xl:max-w-6xl mx-auto'>
                    <div className='flex items-center'>
                        <img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/03/caution.webp" alt='caution' width = "25" />
                        <p className='m-0 text-gray-500 pl-3'>Sub-optimal program design limiting the coverage and impact of the program</p>
                    </div>
                    <div className='flex items-center'>
                        <img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/03/caution.webp" alt='caution' width = "25" />
                        <p className='m-0 text-gray-500 pl-3'>Lack of clear measurement of the program performance and effectiveness</p>
                    </div>
                    <div className='flex items-center'>
                        <img src= "https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/03/caution.webp" alt='caution' width = "25" />
                        <p className='m-0 text-gray-500 pl-3'>Non availability of benchmarks across the program design and execution</p>
                    </div>
                </div>
            </div>
        </section>

        {/* <section className='w-full flex justify-center px-6 mt-5 pb-16 h-SE xl:h-auto'>
            <AireCarousel />
        </section> */}

        <section className='w-full justify-center px-6 py-10 xl:py-16'>
            <h2 className='homepage-heading text-center mx-auto text-gray-500 pb-16 max-w-7xl'>Understand how AIR<sup>e</sup> can help you generate measurable <span className="text-orange">ROI</span> from your R&R program</h2>
            <div id='aireCarousel'></div>
        </section>

        <section class="w-full flex place-content-center relative overflow-x-hidden py-10 xl:py-16 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
            <div class="lg:flex max-w-7xl px-3">
                <div class="card-1 w-full lg:w-1/2 xl:w-2/3 2xl:w-2/3">
                    <h2 class="homepage-heading text-center xl:text-left text-gray-250 font-bold mb-8 md:px-12 lg:px-0 xl:w-8/12">Unlock the <span className='text-orange'>benefits</span>!</h2>
                    <p class="mb-8 text-center text-xl md:text-left mt-2 mb-4 xl:w-8/12 text-gray-500">
                    Here's what you'll get with this consultation:</p>
                    <div className='mt-2 xl:mt-16 mb-8'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/career-tick.webp" alt='career-tick' width= "30" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Gain clarity on the design assessment of your R&R program</p>
                        </div>
                    </div>
                    <div className='my-8 xl:my-12'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/career-tick.webp" alt='career-tick' width= "30" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Get expert assistance to boost your R&R program</p>
                        </div>
                    </div>
                    <div className='my-8 xl:my-12'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/career-tick.webp" alt='career-tick' width= "30" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Analyze & correlate the assessment to your existing program design</p>
                        </div>
                    </div>
                    <div className='my-8 xl:my-12'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/career-tick.webp" alt='career-tick' width= "30" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Understand factors that impact the effectiveness of your R&R program</p>
                        </div>
                    </div>
                    <div className='my-8 xl:my-12'>
                        <div className='flex w-full items-center'>
                            <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/career-tick.webp" alt='career-tick' width= "30" height="20" />
                            <p className='m-0 pl-5 text-gray-500 text-xl'>Learn more about the AIR<sup>e</sup> Framework</p>
                        </div>
                    </div>
                </div>
                <div id="form" className="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2">
                    <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">
                        Schedule a consultation with our experts to enhance your Rewards & Recognition program!
                    </h2>
                     {/* <p class="text-sm mb-8 text-center mt-2">
                        Get a Personalized walkthrough of Vantage Rewards from a friendly member of our team
                    </p> */}
                    <div class="w-full flex justify-center items-center lato team">
                        <HubspotForm
                        portalId="6153052"
                        formId="bced9211-774a-46d6-babd-7ea920e140d4"
                        onSubmit={() => console.log("Submit!")}
                        onReady={form => console.log("Form ready!")}
                        loading={<div>Loading...</div>}
                        />
                    </div>
                    <p class="text-sm text-center">
                        We safeguard your{" "}
                        <a href="/privacy-policy/" class="text-orange">
                        Privacy
                        </a>
                    </p>
                    <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                </div>
            </div>
        </section>
        <section class="w-full flex flex-col place-content-center relative overflow-x-hidden py-9 xl:pt-16 overflow-y-hidden z-0 px-3 lg:px-8 xl:px-0 bg-gray-100">
            <div className='max-w-7xl mx-auto'>
                <h2 class="text-gray-250 homepage-heading font-bold text-center xl:text-left font-bold w-full xl:w-7/12 mb-10 xl:mb-0"><span class="text-orange">Optimize</span> your R&R program across both design and execution.</h2>
                <div class="xl:flex gap-10 justify-center items-center w-full mb-0 lg:mt-8 relative max-w-7xl">
                    <div className='w-full md:w-10/12 mx-auto xl:mx-0 xl:w-5/12 my-5'>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mb-5 bg-white'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/objectives.webp" alt='objectives' width= "50"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Clear program objectives linked to key organizational priorities</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center my-5 bg-white'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/holistic.webp" alt='holistic' width= "50"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Holistic program design with mapping of all program elements</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center my-5 bg-white'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/ROI.webp" alt='ROI' width= "50"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>An ROI model that quantifies program impact and enables stakeholder buy-in</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5 bg-white'>
                            <div className='flex w-3/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/analysis.webp" alt='analysis' width= "50"/>
                            </div>
                            <div className='flex w-9/12 justify-start items-center'>
                                <p className='m-0 text-gray-500'>Program execution with regular analysis and insights to optimize effectiveness</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-10/12 mx-auto xl:w-5/12 flex xl:justify-end justify-center md:mt-16 xl:my-0 items-center z-10 transform translate-y-12 xl:translate-y-8"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/VC-Aire-RR.webp" alt='VC-Aire-RR' width="400" /></div>
                {/* <div class="z-0 hidden lg:block absolute -left-24 lg:-top-28 xl:-top-20 2xl:top-0"><img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-1.png" /></div> */}
                </div>
            </div>
        </section>
        <section className='w-full py-10 xl:py-16'>
            <div className='max-w-7xl mx-auto'>
                <h2 className='homepage-heading text-center'><span className='text-orange'>Why </span>AIR<sup>e</sup> Framework</h2>
                <p className='w-10/12 xl:max-w-5xl mx-auto text-center my-6 text-gray-500 text-xl xl:text-2xl'>The AIR<sup>e</sup> Framework is a powerful tool that gives organizations a baseline for creating a strong foundation for their recognition program. Let your workforce breathe upon and thrive the 'AIR<sup>e</sup>' they need.</p>
                <div className='grid xl:grid-cols-2 mx-auto gap-10 w-10/12 xl:max-w-5xl'>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5 bg-white'>
                            <div className='flex w-2/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp" alt='tick' width= "30"/>
                            </div>
                            <div className='flex w-10/12 justify-start items-center'>
                                <p className='m-0 pl-2 xl:pl-0 text-gray-500'>Design & deliver authentic and impactful recognition</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5 bg-white'>
                            <div className='flex w-2/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp" alt='tick' width= "30"/>
                            </div>
                            <div className='flex w-10/12 justify-start items-center'>
                                <p className='m-0 pl-2 xl:pl-0 text-gray-500'>Assess your existing  program with data-backed analytics</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5 bg-white'>
                            <div className='flex w-2/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp" alt='tick' width= "30"/>
                            </div>
                            <div className='flex w-10/12 justify-start items-center'>
                                <p className='m-0 pl-2 xl:pl-0 text-gray-500'>Promote desired behavior linked with business objectives</p>
                            </div>
                        </div>
                        <div className='border rounded-lg p-3 flex py-4 border-gray-light items-center justify-center mt-5 bg-white'>
                            <div className='flex w-2/12 justify-center item-center'>
                                <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/tick.webp" alt='tick' width= "30"/>
                            </div>
                            <div className='flex w-10/12 justify-start items-center'>
                                <p className='m-0 pl-2 xl:pl-0 text-gray-500'>Align your R&R program with the core organizational values</p>
                            </div>
                        </div>
                </div>
            </div>
            <div className='w-10/12 lg:w-3/12 2xl:w-2/12 mx-auto mt-12'><a href ="#form" className='vc-new-orange-btn-rounded-full'>Book a free consultation</a></div>
        </section>
    </Layout>
  )
}

export default Aire