import React from 'react'
import ReactDOM from "react-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'

const AireCarousel = () => {
  return (
    <div className='w-full h-full'>
        <CarouselProvider className="md:max-w-4xl xl:max-w-7xl relative mx-auto h-full"
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={5}
        isPlaying= {true}
        interval={3000}
        >

            <Slider className='rounded-xl w-full h-500 xl:h-80 grid items-start xl:items-center'>
                <Slide index={0} className="">
                    <div className='xl:flex justify-center items-center xl:items-center overflow-hidden p-6 xl:px-5 h-500 xl:h-80 aireGradient' style={{ background: "rgb(66,66,112)", background: "linearGradient(319deg, rgba(66,66,112,1) 0%, rgba(42,42,79,1) 100%)" }}>
                        <div className='w-full xl:px-5 pb-5 xl:py-5'>
                            <h2 className='text-purple-100 text-2xl md:text-4xl text-center xl:text-left py-5'><span className='text-aireOrange'>A</span><span className='text-red'>I</span><span className='text-darkgreen'>R</span><span className='text-purple-100'><sup>e</sup></span></h2>
                            <p className='text-center text-purple-100 xl:text-left'>Vantage Circle's decade of experience and learnings led us to build the AIR<sup>e</sup> Structure, a new framework and strategy for employee recognition with <span className='text-aireOrange'><span className= "font-bold text-2xl">A</span>ppreciation</span>, <span className='text-red'><span className= "font-bold text-2xl">I</span>ncentivization</span>, and <span className= "text-darkgreen"><span className= "font-bold text-2xl">R</span>einforcement</span> as the key themes of recognition, and <span className= "font-bold text-xl">e</span>Motional Connect as the driving force.</p>
                        </div>
                        <div className='w-full xl:flex xl:justify-end'>
                            <div className=''>
                                <img className='transform translate-x-2 mt-0 xl:mt-0 scale-75 xl:scale-90 -translate-y-16 md:-translate-y-28 xl:translate-y-0 mx-auto' src='https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/AIRE-updated.png' img="AIRE-updated" width= "420" loading='lazy' />
                            </div>
                         
                        </div>
                    </div>
                </Slide>
                <Slide index={1} className="">
                    <div className='xl:flex justify-center items-center xl:items-center overflow-hidden bg-purple-100 p-6 xl:px-5 h-500 xl:h-80'>
                        <div className='w-full xl:px-5 pb-5 xl:py-5'>
                            <h2 className='text-orange text-2xl md:text-4xl text-center xl:text-left py-5'>Appreciation</h2>
                            <p className='text-center xl:text-left text-gray-500'>Appreciation refers to an acknowledgment of employee contributions, be it extraordinary performance, day-to-day tasks, or team efforts.</p>
                        </div>
                        <div className='w-full xl:flex xl:justify-end'>
                            <div className=''>
                                <img className='transform translate-x-2 mt-6 lg:mt-3 xl:mt-0 scale-110 xl:scale-110 mx-auto' src='https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/appreciation_aire.png' alt='appreciation_aire' width= "420" loading='lazy' />
                            </div>
                         
                        </div>
                    </div>
                </Slide>
                <Slide index={2} className="">
                    <div className='xl:flex justify-center items-center xl:items-center overflow-hidden bg-purple-100 p-6 xl:px-5 h-500 xl:h-80'>
                        <div className='w-full xl:px-5 pb-5 xl:py-5'>
                            <h2 className='text-red text-2xl md:text-4xl text-center xl:text-left py-5'>Incentivization</h2>
                            <p className='text-center xl:text-left'>Incentivization is the practice of building monetary and non-monetary incentives into an agreement or system to motivate the workforce.</p>
                        </div>
                        <div className='w-full xl:flex xl:justify-end'>
                            <div className=''>
                                <img className='transform translate-x-0 mt-20 lg:mt-6 xl:mt-8 scale-100 xl:scale-100 mx-auto' src='https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/incentivization_aire.png' alt='incentivization_aire' width= "420" loading='lazy' />
                            </div>
                         
                        </div>
                    </div>
                </Slide>
                <Slide index={3} className="">
                    <div className='xl:flex justify-center items-center xl:items-center overflow-hidden bg-purple-100 p-6 xl:px-5 h-500 xl:h-80'>
                        <div className='w-full xl:px-5 pb-5 xl:py-5'>
                            <h2 className='text-darkgreen text-2xl md:text-4xl text-center xl:text-left py-5'>Reinforcement</h2>
                            <p className='text-center xl:text-left'>Reinforcement refers to positive communication based on the demonstration of key behaviors that the organization wants to promote.</p>
                        </div>
                        <div className='w-full xl:flex xl:justify-end'>
                            <div className=''>
                                <img className='transform translate-x-0 mt-20 lg:mt-10 xl:mt-9 scale-100 xl:scale-100 mx-auto' src='https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/ew-inforcement_aire.png' alt='ew-inforcement_aire' width= "420" loading='lazy' />
                            </div>
                         
                        </div>
                    </div>
                </Slide>
                <Slide index={4} className="">
                    <div className='xl:flex justify-center items-center xl:items-center overflow-hidden bg-purple-100 p-6 xl:px-5 h-500 xl:h-80'>
                        <div className='w-full xl:px-5 pb-5 xl:py-5'>
                            <h2 className='text-indigo-100 text-2xl md:text-4xl text-center xl:text-left py-5'>eMotional Connect</h2>
                            <p className='text-center xl:text-left'>eMotional Connect is the association of strong feelings with the act of recognition and acts as the driving force of authentic and impactful recognition.</p>
                        </div>
                        <div className='w-full xl:flex xl:justify-end'>
                            <div className=''>
                                <img className='transform translate-x-2 mt-12 lg:mt-5 xl:mt-7 scale-90 xl:scale-90 mx-auto' src='https://res.cloudinary.com/vantagecircle/image/upload/v1645004132/gatsbycms/uploads/2023/05/emotionalconnect_aire.png' alt='emotionalconnect_aire' width= "420" loading='lazy' />
                            </div>
                         
                        </div>
                    </div>
                </Slide>
               
            </Slider>
            

            <div className="w-full flex justify-center items-center">
                <DotGroup className = "mx-auto py-5" id = "dots"/>
            </div>
        </CarouselProvider>
    </div>
  )
}

const renderAireCarousel=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#aireCarousel")
        if (id != null){
            ReactDOM.render(
                <AireCarousel />,
                id
            )
        }
    }
}

setTimeout(renderAireCarousel,100)

export default AireCarousel